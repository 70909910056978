import styled from "styled-components";

export const SubmitTextBtn = styled.button`
  box-sizing: border-box;
  appearance: none;
  background-color: white;
  border: 2px solid #3498db;
  cursor: pointer;
  font-size: 1.3rem;
  line-height: 1;
  padding: 0.7em 0.1em;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 6px;
  color: #3498db;
  position: relative;
  overflow: hidden;
  transition: color 250ms ease-in-out;
  width: 30%;
  display: flex;
  align-items: center;
  padding-right: 0;
  justify-content: center;


  &:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: #3498db;
    z-index: -1;
    transition: background 250ms ease-in-out;
  }

  i {
    font-size: 26px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media only screen and (max-width: 500px) {
    width: 70%;
  }

  @media only screen and (max-width: 350px) {
    width: 100%;
  }

  &:hover {
    color: #fff;
    outline: 0;
    background: #3498db;

    &:after {
      width: 110%;
    }
  }

  &:focus {
    outline: 0;
    background: #3498db;
  }

  &:disabled {
    opacity: 0.4;
    filter: grayscale(100%);
    pointer-events: none;
  }
`;