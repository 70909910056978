import React, { useRef, useState } from "react";
import { Slider } from "@mui/material";
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  RemoveFileIcon,
  InputLabel,
  SubmitFileBtn
} from "./file-upload.styles";

const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 3500000;
var numberOfPages = 1;
const fileUrl = "https://6hscanexjsimiorhpmnbwmeyi40msomf.lambda-url.us-east-1.on.aws/";

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
  label,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  ...otherProps
}) => {

  const postUploadWaitMessage = "<br>Request Submitted. Please allow up to a minute for us to process the document.<br>"
  const postUploadResultMessage = (url) => {
    return "<br><strong>" + url + "</strong>"
      + "<br><strong>Survey url: <a href=https://forms.gle/Yz6fLYvVZ7rWt8zv9 target=`__blank`/>google form</strong></a>"
      + "<br><strong style=\"color:red;\">IMPORTANT: the url will expire after 30 minutes </strong>" 
  }
  const fileInputField = useRef(null);
  const [fileSubmitButtonStatus, setFileSubmitButtonStatus] = useState(false);
  const [fileBackendResult, setFileBackendResult] = useState("");
  const [files, setFiles] = useState({});
  const [highlighterVolume, setHighlighterVolume] = useState(50);
  const [highlighterSliderStatus, setHighlighterSliderStatus] = useState(true);
  const highlightVolumeMarks = [
    {
      value: 0,
      label: 'less highlight',
    },
    {
      value: 100,
      label: 'more highlight',
    },
  ];

  const handleFileSubmit = (event) => {
    event.preventDefault();
    console.log("file submit clicked")
    console.log(files.file)
    const formData = new FormData();
    formData.append("username", "user00");
    formData.append("file", files.file);
    setFileSubmitButtonStatus(false)
    setHighlighterSliderStatus(false)
    setFileBackendResult(postUploadWaitMessage)
    fetch(fileUrl, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "Content-Type": "application/pdf",
        "confidence": 100-highlighterVolume,
      },
      redirect: "follow", // manual, *follow, error
      body: formData // body data type must match "Content-Type" header
    }).then(response => {
      return response.text()
    }).then(url => {
      console.log(url)
      setFileBackendResult(postUploadResultMessage(url))
      setFileSubmitButtonStatus(true)
      setHighlighterSliderStatus(true)
    }).catch(error => {
      console.log(error)
    })
  };

  const handleHighlighterVolumeChange = (event) => {
    setHighlighterVolume(event.target.value);
  }

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      if (file.size <= maxFileSizeInBytes) {
        if (!otherProps.multiple) {
          setFileSubmitButtonStatus(true)
          setFileBackendResult("")
          return { file };
        }
        files[file.name] = file;
      } else {
        setFileSubmitButtonStatus(false);
        setFileBackendResult("<strong><h2 style=\"color:red;\">File size cannot exceed 3 MB<h2></strong>");
        return { file };
      }
    }
    return { ...files };
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      let updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
    }
  };

  const removeFile = (fileName) => {
    delete files[fileName];
    setFiles({ ...files });
  };

  return (
    <>
      <FileUploadContainer>
        <InputLabel>{label}</InputLabel>
        <DragDropText>Drag and drop your file or</DragDropText>
        <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
          <i class="fa-solid fa-paper-plane"><span>&#10146;</span></i>
          <span> Choose {otherProps.multiple ? "files" : "file"}</span>
        </UploadFileBtn>
        <FormField
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          title=""
          value=""
          {...otherProps}
        />
      </FileUploadContainer>
      <FilePreviewContainer>
        <span><DragDropText>To be Uploaded:</DragDropText></span>
        <PreviewList>
          {Object.keys(files).map((fileName, index) => {
            let file = files[fileName];
            let isImageFile = file.type.split("/")[0] === "image";
            return (
              <PreviewContainer key={fileName}>
                <div>
                  {isImageFile && (
                    <ImagePreview
                      src={URL.createObjectURL(file)}
                      alt={`file preview ${index}`}
                    />
                  )}
                  <FileMetaData isImageFile={isImageFile}>
                    <span>{file.name}</span>
                    <aside>
                      <span>{convertBytesToKB(file.size)} kb</span>
                      <RemoveFileIcon
                        onClick={() => removeFile(fileName)}
                      ><span style={{ fontSize: '20px' }}>&#10006;</span></RemoveFileIcon>
                    </aside>
                  </FileMetaData>
                </div>
              </PreviewContainer>
            );
          })}
        </PreviewList>
      </FilePreviewContainer>
      <div>
          <>How much content do you want to highlight?</>
          <Slider 
          aria-label="Volume" 
          value={highlighterVolume} 
          style={{width: '50%', justifyItem: 'center', marginTop: '4%', marginRight:'13%', marginLeft:'7%'}}
          marks={highlightVolumeMarks}
          onChange={handleHighlighterVolumeChange}
          disabled={!highlighterSliderStatus}
          />
          <SubmitFileBtn
        type="submit"
        onClick={handleFileSubmit}
        style={{ float: 'right' }}
        disabled={!fileSubmitButtonStatus}
      >submit file</SubmitFileBtn>
      </div>
      <div dangerouslySetInnerHTML={{ __html: fileBackendResult }} />
    </>
  );
};

export default FileUpload;
