import React, { useRef, useState } from "react";
import { TextField, Slider } from "@mui/material";
import { styled } from '@mui/material/styles';
import { SubmitTextBtn } from "./text-upload.styles";

const textUrl = "https://t9xykbbiya.execute-api.us-east-1.amazonaws.com/prod/";

const InputTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'black',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'black',
    borderWidth: '3px',
    borderRadius: '6px'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'black',
      borderWidth: '3px',
      borderRadius: '6px'
    },
    '&:hover fieldset': {
      borderColor: 'black',
      borderWidth: '3px',
      borderRadius: '6px'

    },
    '&.Mui-focused fieldset': {
      borderColor: 'black',
      borderWidth: '3px'
    },
  },
});

const TextUpload = ({
  label,
  id,
  minRows,
  ...otherProps
}) => {

  const [textBackendResult, setTextBackendResult] = useState('')
  const [textInput, setTextInput] = useState('');
  const [textSubmitButtonStatus, setTextSubmitButtonStatus] = useState(false);
  const [highlighterVolume, setHighlighterVolume] = useState(50);
  const [highlighterSliderStatus, setHighlighterSliderStatus] = useState(true);


  const textInputField = useRef(null);
  const submitTextButton = useRef(null);
  const highlightVolumeMarks = [
    {
      value: 0,
      label: 'less highlight',
    },
    {
      value: 100,
      label: 'more highlight',
    },
  ];

  const handleTextInputChange = event => {
    setTextInput(event.target.value);
    if (event.target.value.length > 0){
      setTextSubmitButtonStatus(true)
    } else {
      setTextSubmitButtonStatus(false)
    }
  };

  const handleHighlighterVolumeChange = (event) => {
    setHighlighterVolume(event.target.value);
  }

  const handleTextSubmit = (event) => {
    event.preventDefault();
    setTextSubmitButtonStatus(false);
    setHighlighterSliderStatus(false);
    console.log("text submit clicked");
    fetch(textUrl, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow", // manual, *follow, error
      body: JSON.stringify({
        text: textInput,
        confidence: 100 - highlighterVolume
      }) // body data type must match "Content-Type" header
    }).then(response => {
      return response.text()
    }).then(text => {
      setTextBackendResult(text);
      setTextSubmitButtonStatus(true);
      setHighlighterSliderStatus(true);
    }).catch(error => {
      console.log(error)
    })
  };

  return (
    <div class="row">
      <div class="column">
        <InputTextField
          fullWidth
          multiline
          minRows={minRows}
          id={id}
          label={label}
          ref={textInputField}
          margin="normal"
          borderColor='black'
          borderWidth='10px'
          style={{
            background: "#FFFFFF",
          }}
          value={textInput}
          onChange={handleTextInputChange}
        />
        <div>
          <>How much content do you want to highlight?</>
          <Slider 
          aria-label="Volume" 
          value={highlighterVolume} 
          style={{width: '50%', justifyItem: 'center', marginTop: '4%', marginRight:'13%', marginLeft:'7%'}}
          marks={highlightVolumeMarks}
          onChange={handleHighlighterVolumeChange}
          disabled={!highlighterSliderStatus}
          />
          <SubmitTextBtn
            type="submit"
            onClick={handleTextSubmit}
            style={{ float: 'right', marginTop: '1%' }}
            ref={submitTextButton}
            disabled={!textSubmitButtonStatus}
          >submit</SubmitTextBtn>
        </div>
      </div>
      <div class="column">
        <div dangerouslySetInnerHTML={{ __html: textBackendResult }} />
      </div>
    </div>
  )
}

export default TextUpload;
