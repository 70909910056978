import React, { useState } from "react";

import FileUpload from "./components/file-upload/file-upload.component";
import TextUpload from "./components/text-upload/text-upload.component";
import doureadNameTitle from "./assets/douread-name-title.png"
import douLogo from "./assets/DOU-logo.png"
import giveUsMoreDialog from "./assets/give-us-more-dialog.png"
import textInstructions from "./assets/text-instructions.png"
import arrowRight from "./assets/arrow-right.png"
import fileInstructions from "./assets/file-instructions.png"
import fileNotice from "./assets/file-notice.png"


function App() {
  return (
    <div style={{
      backgroundColor: '#F5F5DC',
      minWidth: '800px'
    }}>
      <div class="row" >
        <img src={doureadNameTitle} alt="" style={{ width: '30%' }}></img>
        <img src={arrowRight} alt="" style={{ width: '20%' }}></img>
        <img src={textInstructions} alt="" style={{ width: '25%' }}></img>
      </div>
          <form>
            <TextUpload
              id="input-text"
              label="Type or paste what you want to highlight here:"
              minRows={10}
            />
          </form>
      <div class="row">
        <img src={douLogo} alt="" style={{
          width: '15%',
          height: '15%',
          alignSelf: 'center',
          float: 'right',
          padding: '5px',
          marginRight: '5%',
          marginTop: '5%'
        }} />
        <img src={giveUsMoreDialog} alt="" style={{
          width: '20%',
          height: '20%',
          alignSelf: 'center',
          float: 'right',
          padding: '5px'
        }} />
      </div>
      <div class="row">
        <div class="column">
          <form>
            <FileUpload
              accept=".pdf"
              label="Upload you file"
              multiple={false}
            />
          </form>
        </div>
        <div class="column">
          <img src={fileInstructions} alt="" style={{ width: '50%' }}></img>
          <img src={fileNotice} alt="" style={{ width: '50%' }}></img>
        </div>
      </div>
      <div class="row">v 0.2.02052301</div>
    </div>
  );
}

export default App;
